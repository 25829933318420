import React from "react";
import HeaderTitle from "../../components/common/HeaderTitle";
import Loader from "../../components/common/Loader";
import Error from "../../components/common/Error";
import { useChat } from "../../context/chat/ChatContext";
import ChatPage from "../../components/features/chats/ChatPage";
import client from "../../api/client";
import ChatPageNoRooms from "../../components/features/chats/ChatPagesNoRooms";
function Chat() {
  const { chats, chatsNoRoom, loading, error } = useChat();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  console.log("from chat", chatsNoRoom);
  console.log("from chat", chats.data);
  return (
    <>
      <HeaderTitle
        title="Chats"
        imgSrc={
          "https://static-00.iconduck.com/assets.00/chat-icon-2048x2048-i7er18st.png"
        }
      />

      <div className="flex items-center justify-center gap-10 flex-wrap">
        <div className="w-full md:w-1/2 lg:w-1/3 h-[90vh] overflow-y-scroll">
          <h3 className="text-2xl my-10 font-semibold">Chat Rooms</h3>

          <ChatPage data={chats.data || []} />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/3 h-[90vh] overflow-y-scroll">
          <h3 className="text-2xl my-10 font-semibold">Chat </h3>

          <ChatPageNoRooms data={chatsNoRoom || []} />
        </div>
      </div>
    </>
  );
}

export default Chat;
