import React from "react";
import imgTime from "../../../assets/images/time.png";
import map from "../../../assets/images/map.png";
import avatar from "../../../assets/images/Avatar.png";

function TransactionItem({ isActive, transaction }) {
  return (
    <div
      className={`p-2 relative border rounded-lg  ${
        isActive ? "border-primary border-2" : "border-[#C0C0C0] border"
      }`}
    >
      <div className="w-[80%] mx-auto">
        <div className="flex items-center justify-between flex-col gap-1">
          {/* Title and Price */}

          <h3 className="text-xs  bg-primary text-white px-2 py-1 rounded-md sm:text-sm md:text-md lg:text-md font-semibold ">
            {transaction.userName || ""}
          </h3>
          <div className="flex items-center w-full justify-between gap-2 md:gap-4">
            <h3 className="text-xs sm:text-sm md:text-md lg:text-md font-semibold text-black">
              {transaction.serviceName || ""}
            </h3>
            <h3 className="text-xs sm:text-sm md:text-md lg:text-md font-normal text-primary">
              $ {transaction.ordaerPrice}
            </h3>
          </div>
          {/* Date */}
          <div className="flex items-center w-full gap-2 md:gap-4">
            <img src={imgTime} alt="Time" className="w-4" />
            <h3 className="text-xs sm:text-sm md:text-md lg:text-md font-normal text-fiveTextColor">
              {`${new Date(
                transaction.createdAt
              ).toLocaleDateString()} ${new Date(
                transaction.createdAt
              ).toLocaleTimeString()}`}
            </h3>
          </div>
          {/* Address */}
          <div className="flex items-center w-full gap-2 md:gap-4">
            <img src={map} alt="Location" className="w-4" />
            <h3 className="text-xs sm:text-sm md:text-md lg:text-md font-normal text-fiveTextColor">
              {transaction.address}
            </h3>
          </div>
          {/* Additional Info */}
          <div className="flex items-center w-full justify-between">
            <div className="flex items-center w-full gap-2 md:gap-4">
              <img src={imgTime} alt="Time" className="w-4" />
              <h3 className="text-xs sm:text-sm md:text-md lg:text-md font-normal text-fiveTextColor">
                {transaction.chooseTypeRequest}
              </h3>
            </div>
            <h3 className="text-xs sm:text-sm text-primary">
              {" "}
              {transaction.offerCount}
            </h3>
          </div>
        </div>
      </div>
      <div className="absolute top-[30%] left-[-15px] sm:left-[-20px] md:left-[-25px]">
        <img
          src={transaction.userImage || avatar}
          alt="Avatar"
          className="w-8 h-8 md:h-12 rounded-full md:w-12"
        />
      </div>
    </div>
  );
}

export default TransactionItem;
