import React from "react";
import CustomInput from "../../common/CustomInpute";
import MonyTransfersTable from "./MonyTransfersTable";
import { useMoneyTransfer } from "../../../context/mony_transfers/MonyTransfersContext";
import Loader from "../../common/Loader";
import Error from "../../common/Error";
function MonyTransfersContent() {
  const { moneyTransfers, loading, error, updateQuery } = useMoneyTransfer();

  const OnChange = (e) => {
    updateQuery(e.target.value);
  };
  return (
    <div className="p-4 sm:p-6  md:pl-10 md:pr-10 md:pt-16 md:pb-10">
      <div className="flex flex-wrap items-center justify-center gap-4 sm:gap-6 md:gap-8">
        <CustomInput
          parentClassName="!m-0"
          label="Invoice ID"
          onChange={(E) => OnChange(E)}
          error={""}
          placeholder="Enter Invoice Id"
          labelClassName="!text-black !text-sm !font-normal"
          inputClassName="!text-black  !text-sm !border-1 !rounded !focus:!border !border-gray-300"
        />
        {/* <CustomInput
          parentClassName="!m-0"
          label="workers"
          placeholder="Enter workers"
          onChange={(E) => OnChange(E)}
          error={""}
          labelClassName="!text-black !text-sm !font-normal"
          inputClassName="!text-black !text-sm !border-1 !rounded !focus:!border !border-gray-300"
        /> */}
        <CustomInput
          parentClassName="!m-0"
          label="Customer"
          onChange={(E) => OnChange(E)}
          error={""}
          placeholder="Customer"
          labelClassName="!text-black !text-sm !font-normal"
          inputClassName="!text-black !text-sm !border-1 !rounded !focus:!border !border-gray-300"
        />
        <CustomInput
          parentClassName="!m-0"
          label=" Date"
          placeholder=" Date"
          onChange={(E) => OnChange(E)}
          error={""}
          labelClassName="!text-black !text-sm !font-normal"
          inputClassName="!text-black !text-sm !border-1 !rounded !focus:!border !border-gray-300"
        />

        {/* <CustomInput
          parentClassName="!m-0"
          label=" Order Id"
          placeholder=" Order Id"
          onChange={(E) => OnChange(E)}
          error={""}
          labelClassName="!text-black !text-sm !font-normal"
          inputClassName="!text-black !text-sm !border-1 !rounded !focus:!border !border-gray-300"
        /> */}
      </div>
      <div className=" w-full mt-10 flex items-center justify-center">
        {loading ? (
          <Loader />
        ) : error ? (
          <Error />
        ) : (
          <MonyTransfersTable data={moneyTransfers.data} />
        )}
      </div>
    </div>
  );
}

export default MonyTransfersContent;
