import React, { useState } from "react";
import CustomButton from "../../common/CustomBtn";
import CustomInput from "../../common/CustomInpute";
import personImage from "../../../assets/images/Profile photo.png";
import DataItem from "./DataItem";
import { useUnderReview } from "../../../context/underPreview/UnderPreview.js";

function WorkerPreviewContent({ data, id }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { changeStatus } = useUnderReview();

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  return (
    <div className="m-4 md:m-12 flex w-full items-center lg:items-start mx-auto justify-center gap-4 flex-col bg-gray-50 rounded-xl p-6 shadow-lg">
      <div className="flex justify-center md:justify-start flex-wrap items-center gap-7 mt-7 p-2 md:p-4">
        {data.imageUrl && (
          <div className="flex items-center gap-3 flex-col hover:scale-105 transform transition-all duration-300 w-32">
            <h3 className="text-sm text-mainTextColor font-semibold">Image</h3>
            <img
              className="w-32 rounded-lg h-20 cursor-pointer shadow-md hover:shadow-lg"
              src={data.imageUrl || personImage}
              alt="Camera"
              onClick={() => openModal(data.imageUrl || personImage)}
            />
          </div>
        )}
        {data.frontID && (
          <div className="flex items-center gap-3 flex-col hover:scale-105 transform transition-all duration-300 w-32">
            <h3 className="text-sm text-mainTextColor font-semibold">
              Front ID
            </h3>
            <img
              className="w-32 rounded-lg h-20 cursor-pointer shadow-md hover:shadow-lg"
              src={data.frontID || personImage}
              alt="Camera"
              onClick={() => openModal(data.frontID || personImage)}
            />
          </div>
        )}
        {data.backID && (
          <div className="flex items-center gap-3 flex-col hover:scale-105 transform transition-all duration-300 w-32">
            <h3 className="text-sm text-mainTextColor font-semibold">
              Back ID
            </h3>
            <img
              className="w-32 rounded-lg h-20 cursor-pointer shadow-md hover:shadow-lg"
              src={data.backID || personImage}
              alt="Camera"
              onClick={() => openModal(data.backID || personImage)}
            />
          </div>
        )}
        {data.selfie && (
          <div className="flex items-center gap-3 flex-col hover:scale-105 transform transition-all duration-300 w-32">
            <h3 className="text-sm text-mainTextColor font-semibold">Selfie</h3>
            <img
              className="w-32 rounded-lg h-20 cursor-pointer shadow-md hover:shadow-lg"
              src={data.selfie || personImage}
              alt="Camera"
              onClick={() => openModal(data.selfie || personImage)}
            />
          </div>
        )}
      </div>

      <div className="flex flex-wrap justify-between gap-6 w-full md:w-10/12 lg:w-8/12">
        <div className="flex flex-col gap-6 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
          <div className="w-full bg-white p-4 rounded-lg shadow-md">
            <DataItem title="Full Name" dataItem={data.fullName} />
          </div>
          <div className="w-full bg-white p-4 rounded-lg shadow-md">
            <DataItem title="Phone Number" dataItem={data.phoneNumber} />
          </div>

          <div className="w-full bg-white p-4 rounded-lg shadow-md">
            <DataItem title="Status" dataItem={data.status} />
          </div>
          <div className="w-full bg-white p-4 rounded-lg shadow-md">
            <DataItem title="Tax Number" dataItem={data.taxNumber} />
          </div>
          <div className="w-full bg-white p-4 rounded-lg shadow-md">
            <DataItem title="City" dataItem={data.cite} />
          </div>
          <div className="w-full bg-white p-4 rounded-lg shadow-md">
            <DataItem title="Zip Code" dataItem={data.zipCode} />
          </div>
        </div>

        <div className="flex flex-col gap-6 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
          <div className="w-full bg-white p-4 rounded-lg shadow-md">
            <DataItem title="Email" dataItem={data.email} />
          </div>
          <div className="w-full bg-white p-4 rounded-lg shadow-md">
            <DataItem title="Bank Name" dataItem={data.bankName} />
          </div>
          <div className="w-full bg-white p-4 rounded-lg shadow-md">
            <DataItem title="IBAN" dataItem={data.iban} />
          </div>
          <div className="w-full bg-white p-4 rounded-lg shadow-md">
            <DataItem title="Account Holder" dataItem={data.accountHolder} />
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative w-full h-full flex items-center justify-center">
            <img
              src={selectedImage}
              alt="Popup"
              className="h-[90vh] w-[90vw] object-contain bg-primary transition-transform duration-300 cursor-pointer transform scale-100 hover:scale-150"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 px-6 py-3 bg-red-500 text-white rounded-lg font-semibold hover:bg-red-600 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default WorkerPreviewContent;
