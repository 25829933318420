import React, { useState } from "react";
import img from "../../../assets/images/Ellipse 5.png";
import Swal from "sweetalert2";
import { sendMessage } from "../../../api/Chat/ChatApi";
function ChatPage({ data }) {
  const [loading, setLoading] = useState(false); // State to track loading status
  const [isMessagesVisible, setIsMessagesVisible] = useState({}); // Manage visibility for each chat message

  const handleSendMessage = (id) => {
    Swal.fire({
      title: `Send a Message to ${id || "User"}`,
      input: "textarea",
      inputPlaceholder: "Type your message here...",
      showCancelButton: true,
      confirmButtonText: "Send",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      inputValidator: (value) => {
        if (!value) {
          return "Message content cannot be empty!";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const messageContent = result.value;

        // Show loader after message sending begins
        Swal.fire({
          title: "Sending message...",
          html: "Please wait while we send your message.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          const response = await sendMessage(id, messageContent);
          Swal.fire("Sent!", "Your message has been sent.", "success");
        } catch (error) {
          Swal.fire("Error!", "Failed to send the message.", "error");
        }
      }
    });
  };

  return (
    <div className="p-6 space-y-6">
      {data.map((chat) => (
        <div
          key={chat.id}
          className="border rounded-xl bg-white shadow-lg overflow-hidden"
        >
          {/* Header Section */}
          <div
            className="flex items-center justify-between p-4 bg-gray-100 cursor-pointer hover:bg-gray-200 transition"
            onClick={() =>
              setIsMessagesVisible((prevState) => ({
                ...prevState,
                [chat.id]: !prevState[chat.id],
              }))
            }
          >
            <div className="flex items-center gap-4">
              {/* User Image */}
              <img
                src={
                  chat.senderImage ||
                  "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI="
                }
                alt={chat.senderName || "User"}
                className="w-12 h-12 rounded-full border"
              />
              {/* Chat Details */}
              <div>
                <h2 className="text-lg font-semibold text-gray-800">
                  Last Message: {chat.lastMessageContent}
                </h2>
                <p className="text-sm text-gray-600">
                  Sent at:{" "}
                  {new Date(chat.lastMessageTimestamp).toLocaleString()}
                </p>
              </div>
            </div>
            {/* Toggle Button */}
            <button
              className={`bg-gray-300 p-2 rounded-full transform transition-transform ${
                isMessagesVisible[chat.id] ? "rotate-180" : ""
              }`}
            >
              ▼
            </button>
          </div>

          {/* Messages Section */}
          <div
            className={`overflow-hidden transition-all duration-500 ${
              isMessagesVisible[chat.id] ? "max-h-[70vh]" : "max-h-0"
            }`}
          >
            <div className="p-4 bg-white">
              {chat.messages?.length > 0 ? (
                <div className="space-y-4 overflow-y-auto max-h-96">
                  {chat.messages.map((message) => (
                    <div
                      key={message.id}
                      className={`flex ${
                        message.senderId === chat.senderId
                          ? "justify-end"
                          : "justify-start"
                      } cursor-pointer items-start space-x-2`}
                    >
                      <div>
                        <img
                          src={
                            message.senderImage ||
                            "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI="
                          }
                          alt={message.senderName || "Sender Avatar"}
                          className="w-8 h-8 rounded-full"
                        />
                        <p className="text-xs text-gray-500">
                          {message.senderName || "Sender Name"}
                        </p>
                      </div>

                      {/* Message Content */}
                      <div
                        className={`px-4 py-2 rounded-lg text-sm max-w-[80%] ${
                          message.senderId === chat.senderId
                            ? "bg-blue-600 text-white"
                            : "bg-gray-200 text-gray-800"
                        }`}
                      >
                        <p>{message.content || "No content provided"}</p>
                        <span className="block text-xs text-gray-500 mt-1">
                          {message.sentAt
                            ? new Date(message.sentAt).toLocaleString()
                            : "Unknown date"}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-center text-sm text-gray-500">
                  No messages in this conversation.
                </p>
              )}
            </div>
          </div>

          {/* Send Message Button */}
          <div className="p-4 bg-gray-100 flex justify-end space-x-4">
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-700 transition  text-sm"
              onClick={() => handleSendMessage(chat.senderId)}
              disabled={loading}
            >
              {loading ? (
                <span className="flex items-center justify-center">
                  <div className="w-4 h-4 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin"></div>
                  <span className="ml-2">Sending...</span>
                </span>
              ) : (
                "Send   Sender"
              )}
            </button>
            <button
              className="bg-green-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-green-700 transition w-1/2"
              onClick={() => handleSendMessage(chat.receiverId)}
              disabled={loading}
            >
              {loading ? (
                <span className="flex items-center justify-center">
                  <div className="w-4 h-4 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin"></div>
                  <span className="ml-2">Sending...</span>
                </span>
              ) : (
                "Send   Receiver"
              )}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ChatPage;
