import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import { FaEyeSlash, FaRegAddressCard } from "react-icons/fa";
import Swal from "sweetalert2";
import client from "../../../api/client";
import { Typography } from "@mui/material";
const MonyTransfersTable = ({ data }) => {
  const [page, setPage] = useState(1);
  const [workerId, seTWorkerId] = useState(null);
  const rowsPerPage = 10;
  const pageCount = Math.ceil(data.length / rowsPerPage);

  const paginatedData = data.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handlePaidStatus = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to update the payment status to "${status}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await client.put(
            `/api/Dashboard/UpdatePaymentStatusToWorker`,
            null,
            {
              params: {
                orderId: id,
                status: status,
              },
            }
          );

          if (response.status === 200) {
            Swal.fire({
              title: "Updated!",
              text: "The payment status has been successfully updated.",
              icon: "success",
            });
            setTimeout(() => {
              window.location.reload();
            }, 20);
          } else {
            Swal.fire({
              title: "Failed!",
              text: "Something went wrong. Please try again.",
              icon: "error",
            });
          }
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: error.response?.data?.message || "An error occurred.",
            icon: "error",
          });
        }
      }
    });
  };

  const handleWorkerInfo = async () => {
    console.log("Worker info", workerId);  
    if(workerId === null) return
    
    try {
      const response = await client.get(
        `/api/Dashboard/get-worker/${workerId}`
      );

      const worker = response.data.data;
      console.log(worker);

      Swal.fire({
        title: `<strong class="text-2xl font-semibold">${worker.fullName}</strong>`,
        html: `
          <div class="text-left text-sm">
            <p class="font-medium my-2">
              <strong>Email:</strong> ${worker.email}
            </p>
            <p class="font-medium my-2">
              <strong>Phone:</strong> ${worker.phoneNumber}
            </p>
            <p class="font-medium my-2">
              <strong>Bank Name:</strong> ${worker.bankName}
            </p>
            <p class="font-medium my-2">
              <strong>IBAN:</strong> ${worker.iban}
            </p>
            <p class="font-medium my-2">
              <strong>Status:</strong> ${worker.status}
            </p>
            <p class="font-medium my-2">
              <strong>Tax Number:</strong> ${worker.taxNumber}
            </p>
            <p class="font-medium my-2">
              <strong>City:</strong> ${worker.cite}
            </p>
            <p class="font-medium my-2">
              <strong>Zip Code:</strong> ${worker.zipCode || "N/A"}
            </p>
            <div class="mt-4">
              <strong class="text-lg">Images:</strong>
              <div class="grid grid-cols-3 gap-4 mt-2">
                <img src="${
                  worker.imageUrl
                }" alt="Worker" class="w-full h-32 object-cover rounded-lg shadow-md cursor-pointer">
                <img src="${
                  worker.frontID
                }" alt="Front ID" class="w-full h-32 object-cover rounded-lg shadow-md cursor-pointer">
                <img src="${
                  worker.backID
                }" alt="Back ID" class="w-full h-32 object-cover rounded-lg shadow-md cursor-pointer">
              </div>
              <img src="${
                worker.selfie
              }" alt="Selfie" class="w-full h-32 object-cover rounded-lg mt-4 shadow-md cursor-pointer">
            </div>
          </div>
        `,
        imageUrl: worker.imageUrl,
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: "Worker Image",
        confirmButtonText: "Close",
        showCloseButton: true,
        focusConfirm: false,
        customClass: {
          title: "swal-title",
          htmlContainer: "swal-html-container",
        },
        didOpen: () => {
          Swal.getPopup().querySelector(".swal-title").style.fontSize = "24px";
        },
        footer: `
          <div class="text-center mt-4">
            <p class="text-sm text-gray-600">
              <i class="text-xl text-gray-500"><FaRegAddressCard /></i> Worker Information
            </p>
          </div>
        `,
        width: "600px", // Set custom width for the popup
      });
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "An error occurred while fetching the worker data.",
        icon: "error",
      });
    }
  };

  return (
    <div className="overflow-x-auto w-[400px] sm:w-[500px] md:[650px] xl:w-[1024px] 2xl:w-[80vw]">
      <table className="min-w-full bg-white">
        <thead className="whitespace-nowrap">
          <tr className="border-b">
            <th className="pl-4 w-8">
              <input
                id="checkbox"
                type="checkbox"
                className="hidden rounded-md peer"
              />
              <label
                htmlFor="checkbox"
                className="relative flex items-center justify-center p-0.5 peer-checked:before:hidden before:block before:absolute before:w-full before:h-full before:bg-gray-50 w-5 h-5 cursor-pointer bg-blue-500 border border-gray-400 rounded overflow-hidden"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-full fill-white"
                  viewBox="0 0 520 520"
                >
                  <path d="M79.423 240.755a47.529 47.529 0 0 0-36.737 77.522l120.73 147.894a43.136 43.136 0 0 0 36.066 16.009c14.654-.787 27.884-8.626 36.319-21.515L486.588 56.773a6.13 6.13 0 0 1 .128-.2c2.353-3.613 1.59-10.773-3.267-15.271a13.321 13.321 0 0 0-19.362 1.343q-.135.166-.278.327L210.887 328.736a10.961 10.961 0 0 1-15.585.843l-83.94-76.386a47.319 47.319 0 0 0-31.939-12.438z" />
                </svg>
              </label>
            </th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">
              Invoice ID
            </th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">
              Order ID
            </th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">
              Date
            </th>

            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">
              Customer
            </th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">
              Payable Amount
            </th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">
              Tasker
            </th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">
              Worker Due
            </th>

            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">
              Worker Info
            </th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">
              Paid Status
            </th>
          </tr>
        </thead>

        <tbody className="whitespace-nowrap">
          {paginatedData.map((transfer, index) => (
            <tr key={index} className="border-b">
              <th className="pl-4 w-8">
                <input
                  id={`checkbox-${index}`}
                  type="checkbox"
                  className="hidden rounded-md peer"
                />
                <label
                  htmlFor={`checkbox-${index}`}
                  className="relative flex items-center justify-center p-0.5 peer-checked:before:hidden before:block before:absolute before:w-full before:h-full before:bg-gray-50 w-5 h-5 cursor-pointer bg-blue-500 border border-gray-400 rounded overflow-hidden"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-full fill-white"
                    viewBox="0 0 520 520"
                  >
                    <path d="M79.423 240.755a47.529 47.529 0 0 0-36.737 77.522l120.73 147.894a43.136 43.136 0 0 0 36.066 16.009c14.654-.787 27.884-8.626 36.319-21.515L486.588 56.773a6.13 6.13 0 0 1 .128-.2c2.353-3.613 1.59-10.773-3.267-15.271a13.321 13.321 0 0 0-19.362 1.343q-.135.166-.278.327L210.887 328.736a10.961 10.961 0 0 1-15.585.843l-83.94-76.386a47.319 47.319 0 0 0-31.939-12.438z" />
                  </svg>
                </label>
              </th>
              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-primary">
                {transfer.invoiceId}
              </td>
              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-primary">
                {transfer.orderId}
              </td>
              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-fiveTextColor">
                {transfer.date}
              </td>

              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-fiveTextColor">
                {transfer.customerName}
              </td>

              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-fiveTextColor">
                {transfer.payableAmount.toFixed(2)}
              </td>

              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-fiveTextColor">
                {transfer.paidAmount.toFixed(2)}
              </td>

              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-fiveTextColor">
                {transfer.due.toFixed(2)}
              </td>
              <td
                onClick={() => {
                  seTWorkerId( prev =>transfer.workerId);
                  handleWorkerInfo();
                }}
                className="p-4 cursor-pointer text-left text-[14px] sm:text-sm md:text-md font-normal text-fiveTextColor"
              >
                <FaEyeSlash size={30} />
              </td>
              <td
                onClick={() =>
                  handlePaidStatus(
                    transfer.orderId,
                    transfer.paymentStatusToWorker === "pending"
                      ? "paid"
                      : "pending"
                  )
                }
                className={`p-2 cursor-pointer text-center text-[14px] sm:text-sm md:text-md font-normal text-white ${
                  transfer.paymentStatusToWorker === "pending"
                    ? "bg-yellow-500"
                    : "bg-green-500"
                }`}
              >
                {transfer.paymentStatusToWorker}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination component */}
      <div className="flex justify-center my-4">
        <Pagination
          count={pageCount}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </div>
    </div>
  );
};

export default MonyTransfersTable;
