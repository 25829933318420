import React from "react";
import avatar from "../../../assets/images/Avatar.png";
function Offer({ data }) {
  return (
    <div className="w-full mt-7 bg-white shadow-sm p-4 rounded-md">
      <h3 className="text-fiveTextColor font-medium text-sm md:text-lg lg:text-xl">
        My Offer
      </h3>
      {data?.offers?.map((offer, index) => (
        <div className="flex items-center my-6 gap-4">
          <img src={offer.offerWorkerImage || avatar} className="w-8 rounded-full" alt="avatar" />
          <div className="flex flex-col gap-1">
            <p className="text-xs sm:text-sm text-fiveTextColor ">
              Task Offered By :{offer.offerWorkerName			}
            </p>
            <p className="text-xs sm:text-sm  text-primary">
              price : {offer.offerPrice}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Offer;
