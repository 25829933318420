import React from "react";
import UserWorkingCard from "./UserWorkingCard";
import cleaning from "../../../assets/images/cleaning.png";
import electrician from "../../../assets/images/electrican.png";
import repairing from "../../../assets/images/reparing.png";
import carpet from "../../../assets/images/electrican.png";
import toramba from "../../../assets/images/toramba.png";
import avatar from "../../../assets/images/Avatar.png";

function UserInfo({ data }) {
  console.log("userinfo", data);
  const services = [
    { imgSrc: cleaning, text: "Cleaning" },
    { imgSrc: electrician, text: "Electrician" },
    { imgSrc: repairing, text: "Repairing" },
    { imgSrc: carpet, text: "Carpet Installation" },
  ];

  return (
    <div className="w-full bg-white shadow-sm p-4 rounded-md">
      <h3 className="text-primary font-medium text-sm md:text-lg lg:text-xl">
        Selected User
      </h3>
      <p className="text-xs sm:text-sm text-fiveTextColor pt-2">Address:</p>
      <p className="text-xs sm:text-sm pb-2 text-black">{data.address}</p>
      <p className="text-xs sm:text-sm text-fiveTextColor pt-2">
        Service Name:
      </p>
      <p className="text-xs sm:text-sm pb-2 text-black">
        {data.serviceName || ""}
      </p>
      {/* <p className="text-xs sm:text-sm text-fiveTextColor py-2">
        Service Type:
      </p> */}
      {/* <div className="flex items-center gap-2 flex-wrap justify-center">
        {services.map((service, index) => (
          <UserWorkingCard
            key={index}
            imgSrc={service.imgSrc}
            text={service.text}
          />
        ))}
      </div> */}
      <p className="text-sx sm:text-sm text-fiveTextColor pt-2 ">details:</p>
      <p className="text-sx sm:text-sm text-fiveTextColor pb-2 ">
        {data.details}
      </p>

      <p className="text-xs sm:text-sm text-fiveTextColor py-2">Attachments:</p>
      <div className="flex items-center gap-2 flex-wrap justify-center">
        {data.attachments?.map((attachment, index) => (
          <img
            key={index}
            src={attachment || toramba}
            alt="toramba"
            className="w-20 h-20 rounded-full "
          />
        ))}
      </div>

      <div className="flex items-center gap-2">
        <img src={avatar} alt="avatar" />
        <p className="text-xs sm:text-sm text-fiveTextColor py-2">
          Task Posted By : {data.userName}
        </p>
      </div>
    </div>
  );
}

export default UserInfo;
