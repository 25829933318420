import Swal from "sweetalert2";
import { sendMessage } from "../../../api/Chat/ChatApi";

function MessageCard({ message, chat }) {
  const isUserOrderedSender = message.senderId === chat.userOrdered;

  const handleSendMessage = (id) => {
    Swal.fire({
      title: `Send a Message to ${id || "User"}`,
      input: "textarea",
      inputPlaceholder: "Type your message here...",
      showCancelButton: true,
      confirmButtonText: "Send",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      inputValidator: (value) => {
        if (!value) {
          return "Message content cannot be empty!";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const messageContent = result.value;
  
        // Show loader after message sending begins
        Swal.fire({
          title: "Sending message...",
          html: "Please wait while we send your message.",
          allowOutsideClick: false, // Prevent closing Swal during loading
          didOpen: () => {
            Swal.showLoading(); // Show loading spinner
          },
        });
  
        try {
          const response = await sendMessage(id, messageContent);
  
          // After message is sent, show success and close the loader
          Swal.fire("Sent!", "Your message has been sent.", "success");
        } catch (error) {
          // Show error message and close the loader
          Swal.fire("Error!", "Failed to send the message.", "error");
        }
      }
    });
  };
  
  return (
    <div
      onClick={() => handleSendMessage(message.senderId)}
      className={`flex cursor-pointer ${
        isUserOrderedSender ? "justify-start" : "justify-end"
      } items-start space-x-2`}
    >
      <div>
        <img
          src={
            message.senderImage ||
            "https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
          }
          alt={message.senderName || "Sender Avatar"}
          className="w-8 h-8 rounded-full"
        />
        <p className="text-[7px] text-gray-500">
          {message.senderName || "Sender Name"}
        </p>
      </div>

      {/* Message Content */}
      <div
        className={`px-4 py-1 rounded-lg text-[10px] ${
          isUserOrderedSender
            ? "bg-gray-200 text-gray-800"
            : "bg-primary text-white"
        }`}
      >
        <p>{message.messageContent || "No content provided"}</p>
        <span className="block text-[7px] text-gray-500 mt-1">
          {message.sentDate
            ? new Date(message.sentDate).toLocaleString()
            : "Unknown date"}
        </span>
      </div>
    </div>
  );
}

export default MessageCard;
