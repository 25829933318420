import React from "react";
import userImage from "../../../assets/images/Ellipse 5.png";
import TransactionSelectedUsers from "./TransactionL&SelectedUsers";
function TransactionContent({transactionData}) {
  return (
    <div className=" p-4 md:pr-8 lg:pl-16 lg:pt-8 lg:pb-8">
      <div className="flex  items-center justify-between">
        <div className="flex flex-col">
          <h3 className="text-xl font-medium text-primary">Transactions</h3>
          <h3 className="text-md font-medium text-fiveTextColor">
            Transfers balance to your users
          </h3>
        </div>
        <div className="flex items-center justify-center gap-3">
          <img src={userImage} alt="UserImage" className="w-8" />
        </div>
      </div>
      <TransactionSelectedUsers transactionData={transactionData} />
    </div>
  ); 
}

export default TransactionContent;
