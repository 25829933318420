import { useState } from "react";
import Swal from "sweetalert2";
import MessageCard from "./MessageCard";
import img from "../../../assets/images/admin.png";

function ChatCard({ chat }) {
  const [isMessagesVisible, setIsMessagesVisible] = useState(false);



  return (
    <div className="border rounded-lg bg-white shadow-lg overflow-hidden">
      {/* Header Section */}
      <div
        className="flex items-center justify-between p-4 bg-gray-100 cursor-pointer hover:bg-gray-200 transition"
        onClick={() => setIsMessagesVisible(!isMessagesVisible)}
      >
        <div className="flex items-center gap-4">
          {/* User Image */}
          <img
            src={chat.senderImage || img}
            alt={chat.userOrderedName || "User"}
            className="w-12 h-12 rounded-full border"
          />
          {/* Chat Details */}
          <div>
            <h2 className="text-lg font-semibold text-gray-800">
              Order #{chat.orderId}
            </h2>
            <p className="text-sm text-gray-600">{chat.orderDetails}</p>
            <p className="text-sm text-gray-600">
              Ordered by:{" "}
              <span className="font-bold text-gray-800">
                {chat.userOrderedName || "Unknown"}
              </span>
            </p>
          </div>
        </div>
        {/* Toggle Button */}
        <button
          className={`bg-gray-300 p-2 rounded-full transform transition-transform ${
            isMessagesVisible ? "rotate-180" : ""
          }`}
        >
          ▼
        </button>
      </div>

      {/* Messages Section */}
      <div
        className={`overflow-hidden transition-all duration-500 ${
          isMessagesVisible ? "max-h-[70vh]" : "max-h-0"
        }`}
      >
        <div className="p-4 bg-white">
          {chat.messages?.length > 0 ? (
            <div className="space-y-4 overflow-y-auto max-h-96">
              {chat.messages.map((message) => (
                <MessageCard
                  key={message.id}
                  message={message}
                  chat={chat}
                  isSentByUser={message.senderId === chat.userOrdered}
                />
              ))}
            </div>
          ) : (
            <p className="text-center text-sm text-gray-500">
              No messages in this conversation.
            </p>
          )}
        </div>
        {/* Send Message Button */}
        {/* <div className="p-4 bg-gray-100 flex justify-end">
          <button
            className="bg-primary text-white px-4 py-2 rounded shadow-md hover:bg-primaryDark transition"
            onClick={handleSendMessage}
          >
            Send Message
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default ChatCard;
